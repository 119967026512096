// FireworksEffect.js
import * as THREE from 'three';

export class FireworksEffect {
  constructor(globe, position, globeRadius, params, color = 0x00BFFF) {
    this.globe = globe;
    this.position = position;
    this.globeRadius = globeRadius;
    this.params = params;
    this.color = color;
    this.beam = null;
    this.createdAt = Date.now();
    this.createBeam();
    this.globe.add(this.beam);
  }

  createBeam() {
    const beamGeometry = new THREE.CylinderGeometry(0.02, 0.02, this.params.beamHeight, 32);
    const beamMaterial = new THREE.MeshPhongMaterial({
      color: this.color,
      transparent: true,
      opacity: 0.7,
      emissive: this.color,
      emissiveIntensity: 0.5,
    });
    this.beam = new THREE.Mesh(beamGeometry, beamMaterial);

    // Position the beam
    const direction = this.position.clone().normalize();
    const beamPosition = direction.multiplyScalar(this.globeRadius);
    this.beam.position.copy(beamPosition);

    // Orient the beam to point outward from the globe
    this.beam.quaternion.setFromUnitVectors(new THREE.Vector3(0, 1, 0), direction);

    // Move the beam up by half its height to sit on the globe surface
    this.beam.translateY(this.params.beamHeight / 2);
  }

  update() {
    const elapsedTime = Date.now() - this.createdAt;
    if (elapsedTime > this.params.duration) {
      this.globe.remove(this.beam);
      return false;
    }

    // Pulsating effect
    const pulseFactor = 0.1 * Math.sin(elapsedTime * 0.01) + 1;
    this.beam.scale.setX(pulseFactor);
    this.beam.scale.setZ(pulseFactor);

    // Fade out
    const opacity = 1 - (elapsedTime / this.params.duration);
    this.beam.material.opacity = opacity * 0.7;

    return true;
  }
}