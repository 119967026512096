import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { FireworksEffect } from './FireworksEffect';
import { Marker } from './Marker';

const GLOBE_RADIUS = 11;
const EFFECT_TYPE = 'beam';

const SpinningGlobe = forwardRef((props, ref) => {
  const mountRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const rendererRef = useRef(null);
  const controlsRef = useRef(null);
  const globeRef = useRef(null);
  const effectsRef = useRef([]);
  const markersRef = useRef([]);
  const autoRotateRef = useRef(true);
  const websocketRef = useRef(null);
  const fontRef = useRef(null);

  const [rotationSpeed, setRotationSpeed] = useState(0.001);
  const [pullRequests, setPullRequests] = useState(0);

  const [effectsParams, setEffectsParams] = useState({
    duration: 7000,
    beamHeight: 2,
  });

  const latLongToVector3 = (lat, lon, radius) => {
    const phi = THREE.MathUtils.degToRad(90 - lat);
    const theta = THREE.MathUtils.degToRad(lon + 180);
    const x = -radius * Math.sin(phi) * Math.cos(theta);
    const y = radius * Math.cos(phi);
    const z = radius * Math.sin(phi) * Math.sin(theta);
    return new THREE.Vector3(x, y, z);
  };


  const getbeamcolor = (urntype) => {
    if (urntype == "urntype_vn_capture"){
      return 0x00BFFF;
    }
    else {
      return 0xFF00BF;
    }
  }
  const addEffect = (lat, lon, cityName = "Unknown Location", urntype) => {
    const position = latLongToVector3(lat, lon, GLOBE_RADIUS);
    if (globeRef.current && fontRef.current) {

      const effect = new FireworksEffect(globeRef.current, position, GLOBE_RADIUS, effectsParams, getbeamcolor(urntype));
      effectsRef.current.push(effect);
      const marker = new Marker(globeRef.current, position, cityName, fontRef.current);
      markersRef.current.push(marker);
    } else {
      console.error('Globe reference or font is not available');
    }
  };

  useImperativeHandle(ref, () => ({
    addEffect,
    setRotationSpeed: (speed) => {
      setRotationSpeed(speed);
    }
  }));

  useEffect(() => {
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0x000033);
    sceneRef.current = scene;

    const camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 2000);
    cameraRef.current = camera;
    camera.position.set(0, 15, 30);

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    rendererRef.current = renderer;
    renderer.setSize(window.innerWidth, window.innerHeight);
    mountRef.current.appendChild(renderer.domElement);

    // Load font
    const fontLoader = new FontLoader();
    fontLoader.load('helvetiker_regular.typeface.json', (loadedFont) => {
      fontRef.current = loadedFont;
      console.log('Font loaded successfully');
    });

    // Create star field
    const starGeometry = new THREE.BufferGeometry();
    const starMaterial = new THREE.PointsMaterial({
      color: 0xFFFFFF,
      size: 0.05,
      sizeAttenuation: false
    });

    const starVertices = [];
    for (let i = 0; i < 10000; i++) {
      const x = (Math.random() - 0.5) * 2000;
      const y = (Math.random() - 0.5) * 2000;
      const z = (Math.random() - 0.5) * 2000;
      starVertices.push(x, y, z);
    }

    starGeometry.setAttribute('position', new THREE.Float32BufferAttribute(starVertices, 3));
    const stars = new THREE.Points(starGeometry, starMaterial);
    scene.add(stars);

    const geometry = new THREE.SphereGeometry(GLOBE_RADIUS, 64, 64);
    const textureLoader = new THREE.TextureLoader();
    
    textureLoader.load(
      '/earth-texture.jpg',
      (texture) => {
        const globeMaterial = new THREE.MeshPhongMaterial({
          map: texture,
          color: 0x9797B1,
          specular: new THREE.Color(0x333333),
          shininess: 15,
          emissive: new THREE.Color(0x112244),
          emissiveIntensity: 0.1
        });
        const globe = new THREE.Mesh(geometry, globeMaterial);
        
        const glowGeometry = new THREE.SphereGeometry(GLOBE_RADIUS * 1.01, 64, 64);
        const glowMaterial = new THREE.ShaderMaterial({
          uniforms: {
            c: { type: "f", value: 0.1 },
            p: { type: "f", value: 1.4 },
            glowColor: { type: "c", value: new THREE.Color(0x00BFFF) },
          },
          vertexShader: `
            varying float intensity;
            void main() {
              intensity = pow(0.7 - dot(normalize(normal), vec3(0, 0, 1)), 2.0);
              gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
          `,
          fragmentShader: `
            uniform vec3 glowColor;
            uniform float c;
            uniform float p;
            varying float intensity;
            void main() {
              gl_FragColor = vec4(glowColor, smoothstep(0.0, 0.8, intensity) * c);
            }
          `,
          side: THREE.BackSide,
          blending: THREE.AdditiveBlending,
          transparent: true
        });
        const glowMesh = new THREE.Mesh(glowGeometry, glowMaterial);
        globe.add(glowMesh);
        
        scene.add(globe);
        globeRef.current = globe;

        animate();
      },
      undefined,
      (error) => {
        console.error('An error occurred while loading the texture:', error);
      }
    );

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.9);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
    directionalLight.position.set(5, 3, 5);
    scene.add(directionalLight);

    const controls = new OrbitControls(camera, renderer.domElement);
    controlsRef.current = controls;
    controls.enableDamping = true;
    controls.dampingFactor = 0.25;
    controls.enableZoom = true;
    controls.minDistance = GLOBE_RADIUS + 1;
    controls.maxDistance = 50;

    controls.target.set(0, 0, 0);

    const animate = () => {
      requestAnimationFrame(animate);
      if (globeRef.current && autoRotateRef.current) {
        globeRef.current.rotation.y -= rotationSpeed;
      }

      effectsRef.current = effectsRef.current.filter(effect => effect.update());
      markersRef.current = markersRef.current.filter(marker => marker.update(camera));

      controls.update();
      renderer.render(scene, camera);
    };

    const defaultSocket = 'ws://localhost:3001'
    const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL || defaultSocket;
    if (!process.env.REACT_APP_WEBSOCKET_URL) {
      console.warn('REACT_APP_WEBSOCKET_URL is not defined');
    }

    const ws = new WebSocket(websocketUrl);
    ws.onopen = () => {
      console.log('Connected to WebSocket server at: ' + websocketUrl);
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.location && data.location.latitude && data.location.longitude) {
        const { latitude, longitude } = data.location;
        const cityName = data.location.city || 'Unknown City';
        addEffect(latitude, longitude, `${cityName}, ${data.location.country}`, data.urntype);
        setPullRequests(prev => prev + 1);
        console.log(`Added effect at ${latitude}, ${longitude} for ${cityName}`);
      } else {
        console.log('Received message without valid location data');
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };

    websocketRef.current = ws;

    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      mountRef.current.removeChild(renderer.domElement);
      if (websocketRef.current) {
        websocketRef.current.close();
      }
    };
  }, [rotationSpeed, effectsParams]);

  const handleParamChange = (param, value) => {
    setEffectsParams(prev => ({ ...prev, [param]: value }));
  };

  const SliderControl = ({ label, param, min, max, step = 1, decimals = 0 }) => (
    <div style={{ marginBottom: '15px' }}>
      <label style={{ 
        display: 'block', 
        marginBottom: '5px', 
        color: '#00BFFF', 
        fontSize: '14px',
        fontWeight: 'bold'
      }}>
        {label}: {effectsParams[param].toFixed(decimals)}
      </label>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={effectsParams[param]}
        onChange={(e) => handleParamChange(param, parseFloat(e.target.value))}
        style={{ 
          width: '100%',
          appearance: 'none',
          height: '5px',
          borderRadius: '5px',
          background: '#00BFFF',
          outline: 'none',
          opacity: '0.7',
          transition: 'opacity .2s'
        }}
      />
    </div>
  );

  return (
    <div style={{ position: 'relative', width: '100%', height: '100vh', overflow: 'hidden', backgroundColor: '#000033' }}>
      <div ref={mountRef} style={{ width: '100%', height: '100%' }} />
      <div style={{
        position: 'absolute',
        top: '20px',
        left: '20px',
        color: '#00BFFF',
        fontFamily: 'Arial, sans-serif',
        fontSize: '24px',
        fontWeight: 'bold',
      }}>
        <div>Scans</div>
        <div style={{ fontSize: '48px' }}>{pullRequests}</div>
      </div>
      <div style={{
        position: 'absolute',
        bottom: '20px',
        right: '20px',
        width: '250px',
        padding: '20px',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        borderRadius: '10px',
        zIndex: 1000,
        boxShadow: '0 0 10px rgba(0, 191, 255, 0.3)'
      }}>
        <h3 style={{ 
          color: '#00BFFF', 
          marginTop: 0, 
          marginBottom: '20px', 
          fontSize: '18px',
          borderBottom: '1px solid #00BFFF',
          paddingBottom: '10px'
        }}>
          Effect Controls
        </h3>
        <SliderControl label="Duration (ms)" param="duration" min={1000} max={10000} step={100} />
        <SliderControl label="Beam Height" param="beamHeight" min={0.5} max={5} step={0.1} decimals={1} />
      </div>
    </div>
  );
});

export default SpinningGlobe;