import React, { useRef } from 'react';
import SpinningGlobe from './SpinningGlobe';

function App() {
  const globeRef = useRef();

  const handleAddScan = () => {
    // Generate random latitude and longitude
    const lat =  41.9008601;
    const lon = -87.9387143;
    globeRef.current.addEffect(lat, lon, "Chicago", "urntype_vn_capture");

  };

  const handleAddScans = () => {

    const cycles = 4
    const locations = [
      { lat: 33.4484, lon: -112.0740, city: 'Phoenix' },
      { lat: 29.4241, lon: -98.4936, city: 'San Antonio' },
      { lat: 32.7157, lon: -117.1611, city: 'San Diego' },
      { lat: 39.7392, lon: -104.9903, city: 'Denver' },
      { lat: 25.7617, lon: -80.1918, city: 'Miami' },
      { lat: 33.7490, lon: -84.3880, city: 'Atlanta' },
      { lat: 47.6062, lon: -122.3321, city: 'Seattle' },
      { lat: 38.5816, lon: -121.4944, city: 'Sacramento' },
      { lat: 39.9612, lon: -82.9988, city: 'Columbus' },
      { lat: 35.2271, lon: -80.8431, city: 'Charlotte' },
      { lat: 39.7684, lon: -86.1581, city: 'Indianapolis' },
      { lat: 36.1627, lon: -86.7816, city: 'Nashville' },
      { lat: 43.0389, lon: -87.9065, city: 'Milwaukee' },
      { lat: 36.1749, lon: -115.1372, city: 'Las Vegas' },
      { lat: 45.5051, lon: -122.6750, city: 'Portland' },
      { lat: 35.4676, lon: -97.5164, city: 'Oklahoma City' },
      { lat: 32.7767, lon: -96.7970, city: 'Dallas' },
      { lat: 29.7604, lon: -95.3698, city: 'Houston' },
      { lat: 39.1031, lon: -84.5120, city: 'Cincinnati' },
      { lat: 40.7128, lon: -74.0060, city: 'New York' },
      { lat: 34.0522, lon: -118.2437, city: 'Los Angeles' },
      { lat: 51.5074, lon: -0.1278, city: 'London' },
      { lat: 48.8566, lon: 2.3522, city: 'Paris' },
      { lat: 35.6895, lon: 139.6917, city: 'Tokyo' },
      { lat: 39.9042, lon: 116.4074, city: 'Beijing' },
      { lat: 55.7558, lon: 37.6173, city: 'Moscow' },
      { lat: -33.8688, lon: 151.2093, city: 'Sydney' },
      { lat: 19.0760, lon: 72.8777, city: 'Mumbai' },
      { lat: -23.5505, lon: -46.6333, city: 'São Paulo' },
      { lat: 31.2304, lon: 121.4737, city: 'Shanghai' },
      { lat: 28.7041, lon: 77.1025, city: 'New Delhi' },
      { lat: 37.7749, lon: -122.4194, city: 'San Francisco' },
      { lat: 41.8781, lon: -87.6298, city: 'Chicago' },
      { lat: 34.6937, lon: 135.5023, city: 'Osaka' },
      { lat: 1.3521, lon: 103.8198, city: 'Singapore' },
      { lat: 52.5200, lon: 13.4050, city: 'Berlin' },
      { lat: 23.1291, lon: 113.2644, city: 'Guangzhou' },
      { lat: 40.4168, lon: -3.7038, city: 'Madrid' },
      { lat: 43.6532, lon: -79.3832, city: 'Toronto' },
      { lat: 30.0444, lon: 31.2357, city: 'Cairo' },
      { lat: 41.0082, lon: 28.9784, city: 'Istanbul' },
      { lat: -34.6037, lon: -58.3816, city: 'Buenos Aires' },
      { lat: 35.9078, lon: 127.7669, city: 'Seoul' },
      { lat: 59.3293, lon: 18.0686, city: 'Stockholm' },
      { lat: 52.3676, lon: 4.9041, city: 'Amsterdam' },
      { lat: 13.7563, lon: 100.5018, city: 'Bangkok' },
      { lat: 22.3193, lon: 114.1694, city: 'Hong Kong' },
      { lat: 55.9533, lon: -3.1883, city: 'Edinburgh' },
      { lat: 37.5665, lon: 126.9780, city: 'Seoul' },
      { lat: 3.1390, lon: 101.6869, city: 'Kuala Lumpur' },
      { lat: -37.8136, lon: 144.9631, city: 'Melbourne' },
      { lat: 25.2048, lon: 55.2708, city: 'Dubai' },
      { lat: 51.0447, lon: -114.0719, city: 'Calgary' },
      { lat: -22.9068, lon: -43.1729, city: 'Rio de Janeiro' },
      { lat: 45.4215, lon: -75.6919, city: 'Ottawa' },
      { lat: 19.4326, lon: -99.1332, city: 'Mexico City' },
      { lat: 50.8503, lon: 4.3517, city: 'Brussels' },
      { lat: -33.9249, lon: 18.4241, city: 'Cape Town' },
      { lat: 39.7392, lon: -104.9903, city: 'Denver' },
      { lat: -36.8485, lon: 174.7633, city: 'Auckland' },
      { lat: 60.1699, lon: 24.9384, city: 'Helsinki' },
      { lat: 45.4642, lon: 9.1900, city: 'Milan' },
      { lat: 47.4979, lon: 19.0402, city: 'Budapest' },
      { lat: 53.3498, lon: -6.2603, city: 'Dublin' },
      { lat: 4.7110, lon: -74.0721, city: 'Bogotá' },
      { lat: 64.1466, lon: -21.9426, city: 'Reykjavik' },
      { lat: 59.9139, lon: 10.7522, city: 'Oslo' },
      { lat: 46.2044, lon: 6.1432, city: 'Geneva' },
      { lat: 45.7640, lon: 4.8357, city: 'Lyon' },
      { lat: 30.0333, lon: 31.2333, city: 'Cairo' },
      { lat: 33.6844, lon: 73.0479, city: 'Islamabad' },
      { lat: 14.5995, lon: 120.9842, city: 'Manila' },
      { lat: -15.7942, lon: -47.8822, city: 'Brasília' },
      { lat: 41.7151, lon: 44.8271, city: 'Tbilisi' },
      { lat: 50.0755, lon: 14.4378, city: 'Prague' },
      { lat: 56.9496, lon: 24.1052, city: 'Riga' },
      { lat: 54.6872, lon: 25.2797, city: 'Vilnius' },
      { lat: 44.4268, lon: 26.1025, city: 'Bucharest' },
      { lat: 42.6977, lon: 23.3219, city: 'Sofia' },
      { lat: 36.2048, lon: 138.2529, city: 'Nagano' },
      { lat: 35.0116, lon: 135.7681, city: 'Kyoto' },
      { lat: 43.7696, lon: 11.2558, city: 'Florence' },
      { lat: 40.6401, lon: 22.9444, city: 'Thessaloniki' },
      { lat: 39.9334, lon: 32.8597, city: 'Ankara' },
      { lat: 32.0853, lon: 34.7818, city: 'Tel Aviv' },
      { lat: 48.1486, lon: 17.1077, city: 'Bratislava' },
      { lat: 47.1625, lon: 19.5033, city: 'Budapest' },
      { lat: 52.2297, lon: 21.0122, city: 'Warsaw' },
      { lat: 41.9028, lon: 12.4964, city: 'Rome' },
      { lat: 59.4369, lon: 24.7536, city: 'Tallinn' },
      { lat: 42.5063, lon: 1.5218, city: 'Andorra la Vella' },
      { lat: 49.2827, lon: -123.1207, city: 'Vancouver' },
      { lat: 53.5461, lon: -113.4938, city: 'Edmonton' },
      { lat: 51.0486, lon: -114.0708, city: 'Calgary' },
      { lat: 45.5017, lon: -73.5673, city: 'Montreal' },
      { lat: 38.7223, lon: -9.1393, city: 'Lisbon' },
      { lat: 41.1579, lon: -8.6291, city: 'Porto' },
      { lat: 37.9838, lon: 23.7275, city: 'Athens' },
      { lat: 36.7213, lon: -4.4214, city: 'Malaga' },
      { lat: 51.2211, lon: 6.7763, city: 'Düsseldorf' },
      { lat: 51.2277, lon: 6.7735, city: 'Düsseldorf' },
      { lat: 39.9139, lon: 32.8560, city: 'Ankara' },
      { lat: 40.4168, lon: -3.7038, city: 'Madrid' },
      { lat: 50.1109, lon: 8.6821, city: 'Frankfurt' },
      { lat: 52.3792, lon: 4.8994, city: 'Amsterdam' },
      { lat: 48.1351, lon: 11.5820, city: 'Munich' },
      { lat: 53.5511, lon: 9.9937, city: 'Hamburg' },
      { lat: 43.3170, lon: -1.9812, city: 'San Sebastian' },
      { lat: 46.9480, lon: 7.4474, city: 'Bern' },
      { lat: 50.9375, lon: 6.9603, city: 'Cologne' },
      { lat: 47.3769, lon: 8.5417, city: 'Zurich' },
      { lat: 50.8503, lon: 4.3517, city: 'Brussels' },
      { lat: 43.7102, lon: 7.2620, city: 'Nice' },
      { lat: 55.6761, lon: 12.5683, city: 'Copenhagen' },
      { lat: 60.3913, lon: 5.3221, city: 'Bergen' },
      { lat: 44.8378, lon: -0.5792, city: 'Bordeaux' },
  ];

  // Convert the array to a Set to remove duplicates
const uniqueLocationsSet = new Set(locations);

// Convert the Set back to an array
const uniqueLocations = Array.from(uniqueLocationsSet);

    // Total duration for the fireworks
    const totalDuration = 15000; // 15 seconds
  

    const fireworkCycle = () => {
      uniqueLocations.forEach((location) => {
        const randomDelay = Math.random() * totalDuration; // Random time within 15 seconds
        setTimeout(() => {
          globeRef.current.addEffect(location.lat, location.lon, location.city, "urntype_vn_capture");
        }, randomDelay);
      });
    };

  for (let i = 0; i < cycles; i++) {
    // Set timeouts for each cycle
    setTimeout(fireworkCycle, i * totalDuration);
  }
  };
  

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <SpinningGlobe ref={globeRef} />
      <button 
        onClick={handleAddScan}
        style={{ position: 'absolute', bottom: '20px', left: '20px', zIndex: 1000 }}
      >
        
        Add Firework
      </button>

      <button 
        onClick={handleAddScans}
        style={{ position: 'absolute', bottom: '50px', left: '20px', zIndex: 1000 }}
      >
        
        Make it rain
      </button>
    </div>
  );
}

export default App;