// Marker.js
import * as THREE from 'three';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';

export class Marker {
  constructor(globe, position, cityName, font) {
    this.globe = globe;
    this.position = position;
    this.cityName = cityName;
    this.font = font;
    this.createdAt = Date.now();
    this.duration = 5000; // Duration in milliseconds
    this.group = new THREE.Group();
    this.createMarker();
    this.createText();
    this.globe.add(this.group);
  }

  createMarker() {
    const markerGeometry = new THREE.SphereGeometry(0.05, 16, 16);
    const markerMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });
    this.marker = new THREE.Mesh(markerGeometry, markerMaterial);
    this.marker.position.copy(this.position);
    this.group.add(this.marker);
  }

  createText() {
    if (this.font) {
      const textGeometry = new TextGeometry(this.cityName, {
        font: this.font,
        size: 0.2,
        height: 0.02,
        curveSegments: 12,
        bevelEnabled: false
      });
      const textMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });
      this.textMesh = new THREE.Mesh(textGeometry, textMaterial);
      
      // Center the text
      textGeometry.computeBoundingBox();
      const textWidth = textGeometry.boundingBox.max.x - textGeometry.boundingBox.min.x;
      this.textMesh.position.set(-textWidth / 2, 0, 0);

      // Create a container for the text
      this.textContainer = new THREE.Object3D();
      this.textContainer.add(this.textMesh);
      
      // Position the text container above the marker
      const textPosition = this.position.clone().normalize().multiplyScalar(this.globe.geometry.parameters.radius + 0.3);
      this.textContainer.position.copy(textPosition);
      
      this.group.add(this.textContainer);
    }
  }

  update(camera) {
    const elapsedTime = Date.now() - this.createdAt;
    if (elapsedTime > this.duration) {
      this.globe.remove(this.group);
      return false;
    }

    // Make text face the camera
    if (this.textContainer) {
      this.textContainer.lookAt(camera.position);
    }

    // Fade out
    const opacity = 1 - (elapsedTime / this.duration);
    this.marker.material.opacity = opacity;
    this.marker.material.transparent = true;
    if (this.textMesh) {
      this.textMesh.material.opacity = opacity;
      this.textMesh.material.transparent = true;
    }

    return true;
  }
}